$white: #fefefe;
$black: #121212;

$red: #f1403e;
$red-20: #fff4f4;

$peach: #f87238;
$peach-20: #fff6ed;

$green: #47a80c;
$green-20: #f4feed;
$green-action: #7df644;
$green-system: #47a80c;
$green-pricing: #43A932;
$green-light: #F4FEED;


$blue: #2986dc;
$blue-20: #f3f8ff;
$blue-30: #edf4ff;

$yellow-20: #FFFDCD;
$dark-yellow-50: #9E9800;

$grey-10: #f8f8f8;
$grey-20: #f5f5f5;
$grey-30: #f0f0f0;
$grey-40: #d9d9d9;
$grey-50: #b9b9b9;
$grey-70: #989898;
$grey-80: #706f6f;
$grey-8080: #808080;
$grey-90: #484848;

$linear-black: linear-gradient(139.68deg, #292929 14.67%, #181818 85.39%);
