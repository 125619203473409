@import "scss/resources";

.RecipientsFile {
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  color: $black;

  & .title {
    @include textC(1.8rem, 400);

    margin-bottom: 1rem;
    color: $black;
  }

  & .fileTitle {
    margin-bottom: 0rem;
  }

  & .fileInput {
    height: 3rem;
  }

  & .uploadedFiles {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & .uploadedFile {
    width: 100%;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: center;
    border-bottom: 1px solid $grey-20;
    gap: 1rem;

    & .fileTitle {
      @include textU(1.6rem, 400);
      @include threeDots();

      max-width: 100%;
    }
  }

  & .clearIcon {
    cursor: pointer;
  }
}
