@import "scss/resources";

.BreadcrumbsGlobalSearchDoc {
  @include textU(1.4rem, 400);

  display: flex;
  align-items: center;
  gap: 4px;
  color: $grey-80;

  & .link {
    @include textU(1.4rem, 400);
    @include threeDots;

    max-width: 11.1rem;
    min-width: 2rem;
    color: $grey-80;

    &.short {
      min-width: 1rem;
    }

    &:visited {
      color: $grey-80;
    }

    &:hover {
      color: $black;
    }
  }

  & .viewActionHistory {
    @include textU(1.4rem, 400);

    display: flex;
    align-items: center;
    gap: 4px;
    color: $red;

    & .button {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
