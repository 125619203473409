@import "scss/resources";

.EmailPasswordName {
  display: flex;
  flex-direction: column;

  &.item {
    width: 35.8rem;
    padding: 1rem 0;
    border-bottom: 1px solid $grey-30;

    &:last-of-type {
      border-bottom: none;
    }
  }

  & .inputWrap {
    position: relative;
  }

  & .input {
    width: 35.8rem;

    &:hover {
      border-color: transparent;
    }

    &:has(input:focus) {
      background: $grey-10;
      border-color: transparent;
    }
  }

  & .edit {
    position: absolute;
    right: 1.5rem;
    top: 3.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
