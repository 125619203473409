@import "scss/resources";

.RequestSigning {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 6rem;
  overflow: visible;

  & .header {
    display: grid;
    grid-template-columns: 10rem auto 10rem;
    z-index: 101;
  }

  & .breadcrumbs {
    display: flex;
    justify-content: center;
  }

  & .closeButton {
    & svg {
      width: 1.2rem;
      height: 2rem;
    }
  }

  & .rightSide {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-top: -1.5rem;
  }

  & .label {
    @include textU(1.6rem, 400);

    padding: 0.2rem 1.2rem;
    margin-right: 3rem;
    color: $grey-80;
    background-color: $grey-20;
    border-radius: 25px;
  }

  & .action {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  & .deleteButton {
    position: absolute;
    top: 6.5rem;
    right: 4rem;
    background: $white;
    border-color: $grey-40;
  }
}
