@mixin centerContent {
  display: flex;
  place-items: center;
  place-content: center;
}

@mixin threeDots {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin textU($size, $weight) {
  font-family: "Urbanist", sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: 140%;
}

@mixin textC($size, $weight) {
  font-family: "Clash", sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: 140%;
}

@mixin useModal() {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100%;
  min-height: 100svh;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateZ(0);
}

@mixin useLinerBorderTop($size) {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $size;
    background: $linear-black;
  }
}

@mixin useLinerBorderBottom($size) {
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $size;
    background: $linear-black;
  }
}

@mixin tooltip($width: max-content) {
  @include textU(12px, 400);
  @include centerContent();

  width: $width;
  padding: 0.2rem 0.8rem 0.4rem;
  text-align: center;
  background-color: $white;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 4px;
  color: $grey-90;
  z-index: 9;
  transition: opacity 0.2s ease-in-out !important;
}

@mixin scrollbar($width: 0.3rem, $height: 0.3rem) {
  &::-webkit-scrollbar {
    height: $height;
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-40;
    border-radius: 10rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
