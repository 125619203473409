@import "scss/resources";

.DocImage {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;

  & .img {
    min-width: 4rem;
    max-width: 4rem;
    max-height: 5rem;
    border-radius: 5px;
  }

  & .certified {
    position: absolute;
    top: -0.3rem;
    left: -0.6rem;
  }

  & .pdfPreview {
    height: auto;
    width: 4rem;
  }
}