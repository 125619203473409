@import "scss/resources";

.RequestEmailDetails {
  width: 100%;
  max-width: 60rem;
  margin: 3rem auto;
  padding: 0;
  display: flex;
  flex-direction: column;

  & .emailLabel {
    @include textU(1.2rem, 400);
  }

  & .emailBody {
    font-family: "Roboto";
    padding: 4.5rem;
    border: 1px solid $grey-30;
    border-radius: 15px;
  }

  & .emailTitle {
    margin-top: 2.5rem;
    font-size: 2.4rem;
    font-weight: 600;
    color: $blue;
  }

  & .emailInfo {
    font-size: 1.6rem;
    line-height: 140%;
    font-weight: 400;
    margin: 2rem 0;
  }

  & .emailAddInfo {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 2rem 0;
  }

  & .message {
    margin-bottom: 2.7rem;

    & .label {
      @include textU(1.2rem, 400);
    }

    & textarea {
      @include textU(1.6rem, 400);
      @include scrollbar;

      width: 100%;
      max-width: 40rem;
      height: 9rem;
      background: $grey-10;
      border-radius: 10px;
      padding: 1.2rem 1.6rem;
      color: $black;
      resize: none;
      border: none;

      &::placeholder {
        color: $grey-70;
      }
    }
  }
  
  & .emailButton:hover {
    color: $white !important;
    cursor: default;
  }

  & .emailBodyFooter {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 140%;
    margin-top: 3.5rem;
  }

  & .emailFooter {
    font-weight: 300;
    font-size: 1.4rem;
    margin: 1.5rem 7.1rem 2.5rem;
    color: $grey-80;
    text-align: center;
  }
}
